import {Location} from "meetinclass/Location";

document.addEventListener('DOMContentLoaded', () => {
    const locationInputs = document.querySelectorAll('select[name=capacity],input[name=location]') as NodeListOf<HTMLInputElement>
    locationInputs.forEach(locationInput => {
        locationInput.addEventListener('change', () => {
            const addressInput = locationInput.form?.querySelector('[name=addrezz]') as HTMLInputElement;
            if (addressInput) {
                addressInput.disabled = locationInput.value.includes(Location.E.REMOTE)
            }
            const distanceInput = locationInput.form?.querySelector('[name=distance]') as HTMLInputElement;
            if (distanceInput)
                distanceInput.disabled = locationInput.value.includes(Location.E.REMOTE)
        })
    })
})
